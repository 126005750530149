<template>
  <CSelect :options="options" :value.sync="selectedOption" @change="setValue" />
</template>

<script>
export default {
  name: "FilterFYPeriod",
  data() {
    return {
      options: [
        {
          value: 1,
          label: "Q1",
        },
        {
          value: 2,
          label: "Q2",
        },
        {
          value: 3,
          label: "Q3",
        },
        {
          value: 4,
          label: "Q4",
        },
        {
          value: 0,
          label: "FY",
        },
      ],
      selectedOption: null,
    };
  },
  methods: {
    getQuarterStartAndEnd(nQ) {
      return {
        start: nQ * 3 + 1 > 12 ? nQ * 3 + 1 - 12 : nQ * 3 + 1,
        end: nQ * 3 + 3 > 12 ? nQ * 3 + 3 - 12 : nQ * 3 + 3,
      };
    },
    setValue() {
      const getQuarter = () => {
        if (this.selectedOption === 0) {
          return {
            start: 4,
            end: 3,
          };
        } else {
          return this.getQuarterStartAndEnd(this.selectedOption);
        }
      };

      this.$store.dispatch("saveFilterMonthSinceValue", {
        value: getQuarter().start,
      });
      this.$store.dispatch("saveFilterMonthUpToValue", {
        value: getQuarter().end,
      });
    },
  },
  props: {
    inactive: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    if (!this.inactive) {
      this.setValue();
    }
  },
};
</script>
